import { animate, easeOut } from 'popmotion'

import detect from 'helpers/detect'
import store from 'stores/store'

window.AudioContext = window.AudioContext || window.webkitAudioContext

const context = new AudioContext()
const gainNode = context.createGain()
gainNode.connect(context.destination)

let audio, paused = true, initialized = false, source

const init = () => {
  audio = new Audio()
  audio.src = 'assets/music/track.mp3'
  audio.crossorigin = 'anonymous'
  audio.onended = onended
  audio.ontimeupdate = ontimeupdate
  audio.currentTime = 1
  // audio.currentTime = 7 * 60

  if (!detect.ios) {
    source = context.createMediaElementSource(audio)
    source.connect(gainNode)
  }
}

const onended = () => {
}
const ontimeupdate = () => {
  if (!audio.duration) return

  if ((audio.currentTime + 10) > audio.duration) {
    store.ended.set(true)
    audio.ontimeupdate = null
  }
}

const play = (start = false) => {
  if (!audio) init()
  if (!paused) return Promise.resolve()

  paused = false
  initialized = true

  fadeIn()
  return audio.play()
}

const pause = () => {
  if (!audio) init()
  if (paused) return Promise.resolve()

  paused = true
  return fadeOut().then(() => {
    audio.pause()
  })
}

const start = () => {
  if (!audio) init()
  context.resume()
  audio.play()
    .then(() => {
      if (!initialized) audio.pause()
    })
    .catch((e) => {
      console.log(e) // eslint-disable-line
    })
}

const fadeIn = () => new Promise(resolve => {
  // resolve()
  animate({ from: 0, to: 1, ease: easeOut, onComplete: resolve, onUpdate: v => (gainNode.gain.value = v) })
})

const fadeOut = () => new Promise(resolve => {
  // resolve()
  animate({ from: 1, to: 0, ease: easeOut, onComplete: resolve, onUpdate: v => (gainNode.gain.value = v) })
})

const TrackPlayer = {
  play,
  pause,
  start
}
export default TrackPlayer
