import React from 'react'

import { map } from 'lodash'
import { motion } from 'framer-motion'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { connect } from 'helpers/state'
import { mq, rem } from 'styles/helpers'

const Link = styled.a`
  & + & {
    margin-left: ${rem(30)};
  }
`

const Logo = styled.img`
  width: ${rem(30)};
  height: ${rem(30)};
  object-fit: contain;

`

const Wrapper = styled(motion.div)`
  margin-top: ${rem(20)};

  ${p => p.small && css`
    ${mq.tabletP} {
      margin-top: ${rem(15)};

      ${Logo} {
        width: ${rem(20)};
        height: ${rem(20)};
      }

      ${Link} + ${Link} {
        margin-left: ${rem(20)};
      }
    }
    }
    `
  }
`

const Socials = ({ loc, ...props }) => {
  return (
    <Wrapper { ...props }>
      { map(loc('end.links'), (link, key) => (
        <Link key={ key } href={ link } target='_blank' rel='noreferrer'>
          <Logo src={ `/assets/svg/${key}.svg` } alt={ key } />
        </Link>
      )) }
    </Wrapper>
  )
}

export default connect('loc')(Socials)
