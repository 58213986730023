import React, { useCallback } from 'react'

import styled from '@emotion/styled'
import { AnimatePresence, motion } from 'framer-motion'
import { useLocation } from 'react-router'

import { colors, mq, rem, snippets } from 'styles/helpers'
import { connect } from 'helpers/state'
import { baseAnimation, fadeAnimation } from 'core/animation'
import Sharer from 'components/sharer/Sharer'

const Wrapper = styled(motion.header)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: ${rem(50)} ${rem(100)} 0;
  z-index: 10;
  display: flex;
  align-items: flex-start;

  ${mq.tabletP} {
    padding: ${rem(20)} ${rem(20)} 0;
  }
`

const Logo = styled(motion.img)`
  width: ${rem(120)};

  ${mq.tabletP} {
    width: ${rem(70)};
  }
`

const Langs = styled(motion.div)`
  position: relative;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  font-size: ${rem(18)};
  font-weight: 600;

  &::after {
    content: '';
    margin-left: ${rem(5)};
    display: block;
    height: 0;
    width: 0;
    border-top: 6px solid ${colors.white};
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
  }
`

const Select = styled.select`
  ${snippets.fullscreen}
  opacity: 0;
`

const Spacer = styled.div`
  flex-grow: 1;
`

const Share = styled(Sharer)`
  background-image: url('/assets/svg/share.svg');
  width: ${rem(16)};
  height: ${rem(20)};
  margin-right: ${rem(18)};
  background-size: contain;
  display: block;
`

const fade = fadeAnimation()

const Header = ({ loc, lang, langs, updateStoreValue, ...props }) => {
  const onLangUpdate = useCallback((event) => {
    const _lang = event.target.value
    history.pushState(null, null, _lang)
    updateStoreValue('lang', _lang)
  }, [])

  const location = useLocation()
  const showLogo = location.pathname === '/experience'

  return (
    <Wrapper>
      <AnimatePresence initial={ false }>
        {showLogo && <Logo { ...fade } { ...baseAnimation } src='/assets/svg/logo.svg' alt={ loc('common.title') } />}
      </AnimatePresence>
      <Spacer />
      <Share />
      <Langs>
        {lang}
        <Select onChange={ onLangUpdate }>
          {langs.map(l => (
            <option selected={ lang === l } key={ l } value={ l }>{l.toUpperCase()}</option>
          ))}
        </Select>
      </Langs>
    </Wrapper>
  )
}

export default connect('loc, lang, langs')(React.memo(Header))
