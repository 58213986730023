const popup = (url, width = 520, height = 300) => {
  window.open(url, 'popin', `width=${width}, height=${height}`)
  // window.open(url, '_blank')
}

// const facebook = (url, callback) => {
//   FB.ui(
//     {
//       method: 'share',
//       href: url
//     },
//     response => {
//       if (response && !response.error_message) callback()
//     }
//   )
// }

// const messenger = (url) => {
//   FB.ui({
//     method: 'send',
//     link: url
//   })
// }

const facebook = (url) => {
  if (!url) url = window.location
  const sharer = 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(url)
  popup(sharer)
}

const linkedin = (url) => {
  if (!url) url = window.location
  const sharer = 'https://www.linkedin.com/shareArticle?mini=true&url=' + encodeURIComponent(url)
  popup(sharer)
}

const twitter = (message, url) => {
  if (!url) url = window.location
  const sharer = 'https://twitter.com/intent/tweet?text=' + encodeURIComponent(message + ' ' + url)
  popup(sharer)
}
// const twitter = (message, url) => {
//   const twitterUrl = 'https://twitter.com/intent/tweet?text=' + encodeURIComponent(message + url)
//   popup(twitterUrl, 520, 300)
// }

export default {
  // messenger,
  linkedin,
  facebook,
  twitter
}
