import { get, memoize, reduceRight } from 'lodash-es'

const l10n = (translations) => {
  return memoize((key, params = false, noFallback = false) => {
    if (key === '@') return ''
    let text = get(translations, key, noFallback === false ? `@missing-key:${key}` : key)
    if (params) text = reduceRight(params, (text, param, key) => text.replace(new RegExp(`%${key}%`, 'gi'), param), text)
    return text
  }, (key, params) => {
    if (!params) return `${key}--${translations}`
    else return `${key}--${JSON.stringify(params)}--${translations}`
  })
}

export default l10n
