import React, { useCallback, useEffect, useRef, useState } from 'react'

import styled from '@emotion/styled'
import { AnimatePresence, motion } from 'framer-motion'
import { useHistory } from 'react-router'

import { mq, rem, snippets } from 'styles/helpers'
import { baseAnimation, fadeAnimation, pageAnimation } from 'core/animation'
import { connect } from 'helpers/state'
import Popin from 'components/popin/Popin'
import CameraController from 'controllers/CameraController'
import useInterval from 'hooks/useInterval'
import TrackPlayer from 'controllers/TrackPlayer'
import detect from 'helpers/detect'
import Socials from 'components/socials/Socials'

const Page = styled(motion.section)`
  ${snippets.absolute('top, left, right, bottom')}
  display: flex;
  justify-content: center;
  align-items: center;
`

const Inner = styled(motion.div)`
  max-width: ${rem(650)};
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${mq.tabletP} {
    padding: 0 ${rem(30)};
  }
`

const InnerLoaded = styled(Inner)`
  ${mq.tabletP} {
    height: 100%;
    padding: ${rem(180)} 0 ${rem(30)};
  }
`

const Tuto = styled(motion.h2)`
  margin-top: ${rem(65)};
  font-size: ${rem(18)};
  line-height: 1.67;
  text-transform: uppercase;
  margin-bottom: ${rem(60)};

  ${mq.tabletP} {
    font-size: ${rem(12)};
    line-height: 1.75;
    margin-bottom: ${rem(40)};
    margin-top: ${rem(0)};
    white-space: pre-wrap;
    padding: 0 ${rem(30)};
  }
`

const Footer = styled.footer`
  font-size: ${rem(12)};
`

const Loading = styled.div`
  font-size: ${rem(12)};

  span {
    font-weight: 300;
  }
`

const Images = styled(motion.div)`
  display: flex;
  height: ${rem(86)};
  margin-bottom: ${rem(220)};

  ${mq.tabletP} {
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    margin-bottom: ${rem(30)};
    height: 100%;
  }
`

const Image = styled.img`
  width: ${rem(235)};
  align-self: flex-end;

  & + & {
    margin-left: ${rem(115)};
    align-self: flex-start;
  }

  ${mq.tabletP} {
    width: ${rem(150)};
    align-self: flex-start;

    & + & {
      margin-left: ${rem(0)};
      margin-top: ${rem(30)};
    }
  }
`

const Spinner = styled.img`
  width: ${rem(150)};
  margin-bottom: ${rem(30)};

  ${mq.tabletP} {
    width: ${rem(100)};
  }
`

const animation = pageAnimation()
const fade = fadeAnimation()
const limit = detect.desktop ? 0.02 : 0.1

const Experience = ({ loc, ended, nocamera }) => {
  const [ready, setReady] = useState(false)
  const [popin, setPopin] = useState(false)
  const opened = useRef(null)
  const popinCount = useRef(-1)
  const history = useHistory()

  useEffect(() => {
    CameraController.checkEyes().then(() => setReady(true))
  }, [])

  useEffect(() => {
    if (!nocamera) return
    TrackPlayer.play()
    setReady(true)
  }, [nocamera])

  useEffect(() => {
    if (ended) history.push('/end')
  }, [ended])

  useInterval(useCallback(() => {
    if (!ready) return
    CameraController.checkEyes().then((score) => {
      const _opened = score > limit
      if (opened.current === _opened) return

      if (opened.current !== null) {
        if (_opened) popinCount.current++
        setPopin(_opened)
      }
      opened.current = _opened

      if (_opened) TrackPlayer.pause()
      else TrackPlayer.play()
    })
  }, [ready]), 1000 / 10)

  return (
    <Page { ...animation }>
      <AnimatePresence initial={ false } exitBeforeEnter>
        {ready
          ? (
            <InnerLoaded key='loaded' { ...baseAnimation } { ...fade }>
              <AnimatePresence initial={ false }>
                {popin && <Popin key={ popinCount.current } count={ popinCount.current } />}
              </AnimatePresence>
              <Tuto>{ loc(nocamera ? 'experience.fallback' : 'experience.tuto') }</Tuto>
              {!nocamera && (
                <Images>
                  <Image src='/assets/svg/close-eyes.svg' />
                  <Image src='/assets/svg/open-eyes.svg' />
                </Images>
              )}
              <Footer>{ loc('experience.footer') }</Footer>
              <Socials small />
            </InnerLoaded>
            )
          : (
            <Inner key='loading' { ...baseAnimation } { ...fade }>
              <Spinner src='/assets/images/spinner.gif' />
              <Loading>
                { loc('experience.loading1') } <br />
                <span>{ loc('experience.loading2') }</span>
              </Loading>
            </Inner>
            )}
      </AnimatePresence>
    </Page>
  )
}

export default connect('loc, ended, nocamera')(React.memo(Experience))
