import * as tf from '@tensorflow/tfjs'

import store from 'stores/store'

import EyeBlink from './EyeBlink'

let webcam, video, blink, loadingPromise
const size = 250, historyLength = 10, history = []

const load = () => {
  blink = new EyeBlink()
  return (loadingPromise = blink.load())
}

const createVideo = () => {
  video = document.createElement('video')
  video.playsInline = true
  video.muted = true
  video.width = size
  video.height = size
  return video
}

const askWebcam = () => {
  return tf.data.webcam(createVideo())
    .then((w) => {
      webcam = w
    })
    .catch(() => {
      store.nocamera.set(true)
    })
}

const checkEyes = () => {
  if (!webcam) return askWebcam().then(checkEyes)
  return loadingPromise
    .then(() => blink.predictEyeOpenness(blink.getImageData(video, { width: size, height: size })))
    .then((result) => {
      if (!result) return 1
      return (result.left + result.right) / 2
    })
    .then((score) => {
      history.push(score)
      while (history.length > historyLength) history.shift()
      return history.reduce((m, v) => {
        return m + v
      }, 0) / history.length
    })
}

const CameraController = {
  load,
  askWebcam,
  checkEyes
}
export default CameraController
