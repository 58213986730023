import React, { useEffect, useRef, useState } from 'react'

import styled from '@emotion/styled'

import { snippets } from 'styles/helpers'

const Video = styled.video`
  ${snippets.fullscreen}
  z-index: -1;
  object-fit: cover;
`
const Fallback = styled(Video)``.withComponent('img')

const Background = () => {
  const [fallback, setFallback] = useState(false)
  const video = useRef()

  useEffect(() => {
    const promise = video.current.play()
    if (promise.catch) {
      promise.catch(() => {
        setFallback(true)
      })
    }
  }, [])

  return fallback ? (
    <Fallback src='assets/videos/fallback.jpg' />
  ) : (
    <Video ref={ video } controls={ false } src='assets/videos/background.mp4' loop playsInline muted preload />
  )
}

export default React.memo(Background)
