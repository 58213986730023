import math from 'helpers/math'

// Fonts
export const fonts = {}

fonts.mainFontname = 'Open Sans'
fonts.main = `'${fonts.mainFontname}', 'Helvetica Neue', 'Arial', sans-serif`

// Colours

export const colors = {}

colors.white = '#fff'
colors.black = '#000'
colors.grey = '#9e9e9e'
colors.green = '#4fd87c'
colors.green2 = '#41b767'
colors.green3 = '#2a944d'
colors.background = colors.green

// Sizes

export const sizes = {}

sizes.leftOffset = 140
sizes.leftOffsetM = 114

sizes.rem = {
  reference: 10,
  widthReference: 1440,
  heightReference: 780
}

sizes.episodeButtonMobile = { width: 25 } // vw
sizes.episodeButtonMobile.height = sizes.episodeButtonMobile.width * (200 / 180)
sizes.episodesMobileHeight = `${sizes.episodeButtonMobile.height}vw - ${math.round(90 / (sizes.rem.reference), 3) + 'rem'}`

export default { fonts, colors, sizes }
