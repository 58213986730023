import React, { useCallback } from 'react'

import NoSleep from 'nosleep.js'
import styled from '@emotion/styled'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'

import { mq, rem, snippets } from 'styles/helpers'
import { pageAnimation } from 'core/animation'
import { connect } from 'helpers/state'
import Button from 'components/button/Button'
import TrackPlayer from 'controllers/TrackPlayer'
import Socials from 'components/socials/Socials'

const Page = styled(motion.section)`
  ${snippets.absolute('top, left, right, bottom')}
  display: flex;
  justify-content: center;
  align-items: center;
`

const Inner = styled(motion.div)`
  max-width: ${rem(650)};
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Logo = styled(motion.img)`
  width: ${rem(250)};

  ${mq.tabletP} {
    width: ${rem(150)};
  }
`
const Subtitle = styled(motion.h3)`
  margin-top: ${rem(65)};
  font-size: ${rem(18)};
  line-height: 1.67;
  text-transform: uppercase;

  ${mq.tabletP} {
    width: ${rem(288)};
    font-size: ${rem(12)};
    line-height: 1.75;
    margin-top: ${rem(40)};
    white-space: pre-wrap;
  }
`
const Sound = styled(motion.div)`
  width: ${rem(250)};
  margin-top: ${rem(60)};
  font-size: ${rem(12)};
  background-image: url('/assets/svg/sound.svg');
  background-size: ${rem(25)} auto;
  background-position: top center;
  padding-top: ${rem(30)};

  ${mq.tabletP} {
    width: ${rem(187)};
    font-size: ${rem(10)};
    margin-top: ${rem(40)};
  }
`
const IntroButton = styled(Button)`
  margin-top: ${rem(40)};
`

const animation = pageAnimation()
const noSleep = new NoSleep()

const Intro = ({ loc }) => {
  const onStart = useCallback(() => {
    noSleep.enable()
    TrackPlayer.start()
  }, [])

  return (
    <Page { ...animation }>
      <Inner>
        <Logo src='/assets/svg/logo.svg' alt={ loc('common.title') } />
        <Subtitle>{ loc('intro.subtitle') }</Subtitle>

        <Sound>{ loc('intro.sound') }</Sound>
        <IntroButton onClick={ onStart } to='/experience' tag={ Link }>{ loc('intro.start') }</IntroButton>
      </Inner>
    </Page>
  )
}

export { Inner as IntroInner, Page as IntroPage, Logo as IntroLogo }
export default connect('loc')(React.memo(Intro))
