import React from 'react'
import { render } from 'react-dom'

import store from 'stores/store'
import resizeStore from 'stores/resizeStore'
import { Provider } from 'helpers/state'

document.scrollingElement.classList.add('scrollingElement')
const _store = Object.assign({}, store, resizeStore)

const renderApp = () => {
  const App = require('core/App').default
  render(
    <Provider store={ _store }>
      <App />
    </Provider>,
    document.querySelector('.app')
  )
}

renderApp()
