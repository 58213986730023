import React from 'react'

import styled from '@emotion/styled'
import { motion } from 'framer-motion'

import { mq, rem } from 'styles/helpers'
import { pageAnimation } from 'core/animation'
import { connect } from 'helpers/state'
import Button from 'components/button/Button'
import { IntroInner, IntroPage, IntroLogo } from 'sections/intro/Intro'
import Socials from 'components/socials/Socials'
import Sharer from 'components/sharer/Sharer'

const Page = styled(IntroPage)``

const Inner = styled(IntroInner)`
  ${mq.tabletP} {
    height: 100%;
    padding: ${rem(120)} 0 ${rem(50)};
  }
`

const Logo = styled(IntroLogo)``

const Subtitle = styled(motion.h3)`
  margin-top: ${rem(65)};
  font-size: ${rem(18)};
  line-height: 1.67;
  text-align: center;
  text-transform: uppercase;

  ${mq.tabletP} {
    width: ${rem(300)};
    font-size: ${rem(12)};
    margin-top: ${rem(40)};
    line-height: 1.75;
    white-space: pre-wrap;
    flex-grow: 1;
  }
`

const Footer = styled(motion.div)`
  margin-top: ${rem(50)};
  font-size: ${rem(12)};

  ${mq.tabletP} {
    margin-top: ${rem(30)};
  }
`

const ShareButton = styled(Sharer)`
  margin-top: ${rem(20)};

  ${mq.tabletP} {
    margin-top: ${rem(30)};
  }
`
const animation = pageAnimation()

const End = ({ loc }) => {
  return (
    <Page { ...animation }>
      <Inner>
        <Logo src='/assets/svg/logo.svg' alt={ loc('common.title') } />
        <Subtitle>
          { loc('end.subtitle1') } <br /><br />
          { loc('end.subtitle2') }
        </Subtitle>
        <Socials />
        <Footer>{ loc('end.share.text') }</Footer>
        <ShareButton>
          <Button>
            { loc('end.share.button') }
          </Button>
        </ShareButton>
      </Inner>
    </Page>
  )
}

export default connect('loc')(React.memo(End))
