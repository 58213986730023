import React, { useEffect } from 'react'

import { Global } from '@emotion/react'
import { AnimatePresence, motion } from 'framer-motion'
import {
  BrowserRouter,
  MemoryRouter,
  useLocation,
  Switch,
  Route,
  Redirect
} from 'react-router-dom'
import styled from '@emotion/styled'

import { colors, fonts } from 'styles/helpers'
import { connect } from 'helpers/state'
import global from 'styles/global'
import Background from 'components/background/Background'
import Intro from 'sections/intro/Intro'
import Header from 'components/header/Header'
import Experience from 'sections/experience/Experience'
import CameraController from 'controllers/CameraController'
import End from 'sections/end/End'
import detect from 'helpers/detect'

import { baseAnimation } from './animation'

const Router = process.env.NODE_ENV === 'development' && !detect.safari && false ? BrowserRouter : MemoryRouter

const Wrapper = styled.div`
  background: ${colors.green};

  &after {
    content: 'a';
    width: 0;
    height: 0;
    opacity: .00001;
    font-family: ${fonts.alternative};
  }
`

const App = ({ mqTabletP, greenBackground }) => {
  useEffect(() => {
    CameraController.load()
  }, [])

  return (
    <>
      <Global styles={ global } />
      <Background />
      <Wrapper>
        <Router>
          <Pages />
        </Router>
      </Wrapper>
    </>
  )
}

const Pages = connect()(React.memo(({ updateStoreValue }) => {
  const location = useLocation()

  return (
    <>
      <Header />

      <AnimatePresence exitBeforeEnter>
        <motion.div { ...baseAnimation } key={ location.pathname }>
          <Switch location={ location }>
            <Route path='/experience$' component={ Experience } />
            <Route path='/end$' component={ End } />
            <Route path='/$' component={ Intro } />
            <Redirect from='/*' to='/' />
          </Switch>
        </motion.div>
      </AnimatePresence>
    </>
  )
}))

export default connect('mqTabletP, greenBackground, openedPDF')(App)
