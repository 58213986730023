import l10n from 'core/l10n'

import { createStore } from '../helpers/state'

const langs = {
  en: require('loc/en.yml'),
  fr: require('loc/fr.yml')
}

const lang = document.documentElement.getAttribute('lang')

const initialState = {
  langs: ['fr', 'en'],
  lang,
  loc: null,
  nocamera: false,
  ended: false
}

const store = createStore(initialState)

store.lang.listenAndStart((lang) => {
  store.loc.set(l10n(langs[lang]))
})

export default store
