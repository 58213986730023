import React from 'react'

import styled from '@emotion/styled'

import { colors, rem } from 'styles/helpers'

const Tag = styled.button`
  display: block;
  padding: ${rem(5)} ${rem(15)};
  font-size: ${rem(12)};
  text-transform: uppercase;
  border-radius: ${rem(4)};
  border: solid 1px ${colors.white};
  font-weight: bold;
  font-family: inherit;
`

const Button = ({ children, tag, ...props }) => {
  return (
    <Tag { ...props } as={ tag }>{children}</Tag>
  )
}

export default React.memo(Button)
