import React from 'react'

import styled from '@emotion/styled'
import { motion } from 'framer-motion'

import { colors, mq, rem, snippets } from 'styles/helpers'
import { connect } from 'helpers/state'
import { baseAnimation, fadeAnimation, popinAnimation } from 'core/animation'

const Wrapper = styled(motion.header)`
  ${snippets.fullscreen}
  position: fixed;
  display: flex;
  z-index: 999;
  align-items: center;
  justify-content: center;
  pointer-events: none;
`

const Background = styled(motion.div)`
  ${snippets.fullscreen}
  background-color: rgba(0, 0, 0, .2);
`

const Image = styled(motion.img)`
  width: ${rem(240)};
  margin-top: ${rem(20)};
  margin-bottom: ${rem(40)};

  ${mq.tabletP} {
    width: ${rem(180)};
    margin-top: ${rem(0)};
    margin-bottom: ${rem(20)};
  }
`

const Inner = styled(motion.div)`
  background: url('assets/images/noise.jpg');
  background-size: ${rem(200)} auto;
  background-repeat: repeat;
  position: relative;
  z-index: 1;
  border-radius: ${rem(8)};
  color: ${colors.black};
  width: ${rem(615)};
  height: ${rem(364)};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: ${rem(100)};

  ${mq.tabletP} {
    max-width: 80%;
    padding: 0 ${rem(20)};
    margin-bottom: ${rem(0)};
    height: ${rem(300)};
  }
`

const Footer = styled.div`
  font-size: ${rem(12)};
  text-transform: uppercase;

  ${mq.tabletP} {
    font-size: ${rem(10)};
  }
`

const Text = styled.div`
  font-size: ${rem(18)};
  line-height: 1.67;
  text-transform: uppercase;
  margin-bottom: ${rem(5)};

  ${mq.tabletP} {
    font-size: ${rem(12)};
    white-space: pre-wrap;
  }
`

const fade = fadeAnimation()
const popin = popinAnimation()

const Popin = ({ loc, count, ...props }) => {
  return (
    <Wrapper { ...baseAnimation }>
      <Background { ...fade } />
      <Inner { ...popin }>
        <Image src='/assets/svg/open-eyes2.svg' />
        <Text>{loc('experience.popin')}</Text>
        <Footer>{loc('experience.footer')}</Footer>
      </Inner>
    </Wrapper>
  )
}

export default connect('loc')(React.memo(Popin))
