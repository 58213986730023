import React from 'react'

import styled from '@emotion/styled'

import { easing, rem } from 'styles/helpers'
import share from 'helpers/share'
import { connect } from 'helpers/state'

const Inner = styled.div`
  position: absolute;
  left: 50%;
  transform: translate(-50%, 100%);
  display: flex;
  bottom: 0;
  padding-top: ${rem(10)};
  pointer-events: none;

`

const Logo = styled.img`
  width: ${rem(20)};
  height: ${rem(20)};
  object-fit: contain;
  opacity: 0;
  transform: translateY(-5px);
  transition:
    opacity .3s ${easing.quadInOut},
    transform .3s ${easing.quadInOut};

  & + & {
    margin-left: ${rem(10)};
    transition-delay: .05s;
  }
`

const Tag = styled.div`
  position: relative;
  cursor: pointer;

  &:hover {
    ${Inner} {
      pointer-events: auto;
    }

    ${Logo} {
      opacity: 1;
      transform: none;
    }
  }
`

const facebook = () => {
  share.facebook(window.location)
}

const Sharer = ({ children, loc, ...props }) => {
  const twitter = () => {
    share.twitter(loc('common.twitter'), window.location)
  }
  return (
    <Tag { ...props }>
      {children}
      <Inner>
        <Logo src='/assets/svg/facebook.svg' onClick={ facebook } />
        <Logo src='/assets/svg/twitter.svg' onClick={ twitter } />
      </Inner>
    </Tag>
  )
}

export default React.memo(connect('loc')(Sharer))
